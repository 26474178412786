.social-wrapper {
  text-align: center;
}

.social-bar {
  display: inline-block;
  margin: 150px auto !important;
}

.social-bar img {
  width: 25px;
  padding: 8px;
  opacity: 0.2;
}

.social-bar img:hover {
  opacity: 0.4;
}