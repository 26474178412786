.page {
  display: flex;
  flex-direction: column;
  overflow: none;
}

.page-content {
  height: calc(100vh - 65px);
  padding-top: 65px; 
  flex: 1
}