@media (max-width: 749px) {
  .hamburger-wrapper {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px;
    margin-bottom: -6px;
  }

  .hamburger-wrapper * {
    cursor: pointer;
  }

  label.hamburger {
    width: 30px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  
  .hamburger span:before {
    top: -8px;
    height: 2px !important;
  }
  
  .hamburger span:after {
    bottom: -1px;
    height: 2px !important;
  }
  
  .hamburger span, .hamburger span:before, .hamburger span:after {
    margin: 5px 0;
    position: relative;
    display: block;
    width: 22px;
    height: 2px;
    background: #626262;
    content: '';
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
  .hamburger span {
    margin: 5px 0;
  }
}