.menu-item {
  font-size: 17px !important;
  text-align: center;
  margin: 15px;
  padding: 1px 4px;
  /* border: 1px solid green; */
}

.menu-item:hover span { 
  opacity: 0.6;
}


.sub-nav {
  display: none;
  background: white;
}

@media (min-width: 750px) {
  .sub-nav {
    background: white;
    position: absolute;
    min-width: 160px;
    z-index: 1;
    margin: 1px -4px ;
  }
  
  .menu-item:hover .sub-nav {
    display: block;
  }

  .menu-item.active {
    border-bottom: 1px solid #626262;
  }
  
  .sub-nav {
    border: 1px solid #626262;
  }
}

@media (max-width: 749px) {
  .menu-item {
    width: 80%;
    margin: 15px 10%;
  }

  .caret {
    float: right;
    width: 10%;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #626262;
    margin-right: 30px;
    margin-top: 9px;
  }

  .caret:hover {
    opacity: 0.6;
  }

  .sub-nav-open {
    display: block;
  }
}
