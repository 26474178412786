.banner{
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.banner-content {
  text-align: center;
  max-width: 866px;
  color: #fffdfd !important;
  margin: 20px auto;
  padding: 80px;
  font-weight: 700 !important;
  letter-spacing: 0px !important;
}

.banner-content * {
  font-weight: 700 !important;
  letter-spacing: 0px !important;
}

.banner-title {
  margin: 0 0 20px 0;
  font-size: 32px !important;
  line-height: 1.15 !important;
}

.banner-text {
  font-size: 18px !important;
  line-height: 1.15 !important;
  font-style: italic;
  text-align: center;
}

.banner-author {
  font-size: 16px !important;
  line-height: 1.15 !important;
  font-style: italic;
  text-align: center;
  margin: 10px 0;
}

.banner-email {
  font-size: 18px !important;
  line-height: 1.15 !important;
  text-align: center;
  margin: 10px 0;
  color: white !important;
  text-decoration: none;
}