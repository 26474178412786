.tiles {
    padding: 20px 0 40px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.gallery-title {
    color: #626262;
    text-align: center;
}