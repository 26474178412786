.sub-menu-item {
  display: block;
  font-size: 17px !important;
  text-align: center;
  margin: 15px;
  padding: 1px 4px
}

.sub-menu-item:hover { 
  opacity: 0.6;
}

@media (min-width: 750px) {
  .sub-menu-item {
    display: block;
    font-size: 17px !important;
    text-align: left;
    margin: 15px;
    padding: 1px 4px
  }
}