.text-block {
  width: 70%;
  padding: 60px 0;
  margin: 20px auto;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
  margin: 0 auto 30px;
  text-align: justify;
  min-width: 300px;
  max-width: 866px;
  color: #626262;
}