.tile {
  position: relative;
  margin: 5px;
  cursor: pointer;
  overflow: hidden;
  width: 25vw;
  height: 18vw;
}

.tile-overlay {
  display: none;
  pointer-events: none;
  position: absolute;
  padding: 4px;
  background: rgba(255, 255, 255, 0.75);
  bottom: 0;
  left: 0;
  right: 0;
}

.tile:hover .tile-overlay {
  display: block;
}

.tile-title {
  font-weight: 700;
  color: #626262;
}

.tile-dimensions {
  font-weight: 300;
  color: #626262;
  font-size: 14px;
  font-style: italic;
}

.tile img {
  width: 100%;
  transition: transform 500ms ease;
  object-fit: cover;
  width: 25vw;
  height: 18vw;
}

.tile img:hover {
  transform: scale(1.2);
}

.expanded-image-wrapper {
  background: rgba(0,0,0,.2);
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.large-image {
  position:fixed;
    top:0;
    bottom:0;
    right: 0;
    left: 0;
  margin: auto;
  max-width: 90vw;
  max-height: 90vh;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.3);
  vertical-align: middle;
}

@media (max-width: 749px) {
  .tile {
    width: 40vw;
    height: 29vw;
  }
  
  .tile img {
    width: 40vw;
    height: 29vw;
  }
}