
.slideshow,
.slideshow:after { 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0; 
}

.slideshow li span { 
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    opacity: 0;
    z-index: 0;
	  -webkit-backface-visibility: hidden;
    -webkit-animation: imageAnimation 24s linear infinite 0s;
    -moz-animation: imageAnimation 24s linear infinite 0s;
    -o-animation: imageAnimation 24s linear infinite 0s;
    -ms-animation: imageAnimation 24s linear infinite 0s;
    animation: imageAnimation 24s linear infinite 0s; 
}
.slideshow li div { 
    z-index: 1000;
    position: absolute;
    bottom: 30px;
    left: 0px;
    width: 100%;
    text-align: center;
    opacity: 0;
    color: #fff;
    -webkit-animation: titleAnimation 24s linear infinite 0s;
    -moz-animation: titleAnimation 24s linear infinite 0s;
    -o-animation: titleAnimation 24s linear infinite 0s;
    -ms-animation: titleAnimation 24s linear infinite 0s;
    animation: titleAnimation 24s linear infinite 0s; 
}
.slideshow li div h3 { 
    font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
    font-size: 240px;
    padding: 0;
    line-height: 200px; 
}

.slideshow li:nth-child(2) span { 
    -webkit-animation-delay: 8s;
    -moz-animation-delay: 8s;
    -o-animation-delay: 8s;
    -ms-animation-delay: 8s;
    animation-delay: 8s; 
}

.slideshow li:nth-child(3) span { 
    -webkit-animation-delay: 16s;
    -moz-animation-delay: 16s;
    -o-animation-delay: 16s;
    -ms-animation-delay: 16s;
    animation-delay: 16s; 
}

/* Animation for the slideshow images */
@-webkit-keyframes imageAnimation { 
    0% { opacity: 0;
    -webkit-animation-timing-function: ease-in; }
    6% { opacity: 1;
         -webkit-animation-timing-function: ease-out; }
    26% { opacity: 1 }
    33% { opacity: 0 }
    100% { opacity: 0 }
}
@-moz-keyframes imageAnimation { 
    0% { opacity: 0;
    -moz-animation-timing-function: ease-in; }
    6% { opacity: 1;
         -moz-animation-timing-function: ease-out; }
    26% { opacity: 1 }
    33% { opacity: 0 }
    100% { opacity: 0 }
}
@-o-keyframes imageAnimation { 
    0% { opacity: 0;
    -o-animation-timing-function: ease-in; }
    6% { opacity: 1;
         -o-animation-timing-function: ease-out; }
    26% { opacity: 1 }
    33% { opacity: 0 }
    100% { opacity: 0 }
}
@-ms-keyframes imageAnimation { 
    0% { opacity: 0;
    -ms-animation-timing-function: ease-in; }
    6% { opacity: 1;
         -ms-animation-timing-function: ease-out; }
    26% { opacity: 1 }
    33% { opacity: 0 }
    100% { opacity: 0 }
}
@keyframes imageAnimation { 
    0% { opacity: 0;
    animation-timing-function: ease-in; }
    6% { opacity: 1;
         animation-timing-function: ease-out; }
    26% { opacity: 1 }
    33% { opacity: 0 }
    100% { opacity: 0 }
}
/* Animation for the title */
@-webkit-keyframes titleAnimation { 
    0% { opacity: 0 }
    6% { opacity: 1 }
    26% { opacity: 1 }
    19% { opacity: 0 }
    100% { opacity: 0 }
}
@-moz-keyframes titleAnimation { 
    0% { opacity: 0 }
    6% { opacity: 1 }
    26% { opacity: 1 }
    19% { opacity: 0 }
    100% { opacity: 0 }
}
@-o-keyframes titleAnimation { 
    0% { opacity: 0 }
    6% { opacity: 1 }
    26% { opacity: 1 }
    19% { opacity: 0 }
    100% { opacity: 0 }
}
@-ms-keyframes titleAnimation { 
    0% { opacity: 0 }
    6% { opacity: 1 }
    26% { opacity: 1 }
    19% { opacity: 0 }
    100% { opacity: 0 }
}
@keyframes titleAnimation { 
    0% { opacity: 0 }
    6% { opacity: 1 }
    26% { opacity: 1 }
    19% { opacity: 0 }
    100% { opacity: 0 }
}
/* Show at least something when animations not supported */
.no-cssanimations .slideshow li span{
	opacity: 1;
}