.cv {
  color: #3F3F3F !important;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

.cv-year {
  white-space: nowrap;
  padding-right: 15px; 
}

.cv a {
  color: #3F3F3F !important;
}