@media (max-width: 749px) {
  .close-hamburger-wrapper {
    cursor: pointer;
    display: inline-block;
    padding: 30px 10px 10px 20px;
  }

  label.close-hamburger span:after {
    top: -2px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  label.close-hamburger span:before {
    /* top: -1px; */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .close-hamburger span:before, .close-hamburger span:after {
    position: relative;
    display: block;
    width: 26px;
    height: 2px;
    background: #626262;
    content: '';
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
  .close-hamburger span {
    margin: 5px 0;
  }
}