.menu {
  background: white;
  z-index: 1;
  color: #626262;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.menu-title {
  text-align: center;
  font-size: 22px;
  margin: 15px 0;
}

.menu-title:hover { 
  opacity: 0.6;
}

@media (min-width: 750px) {
  .menu div {
    display: inline-block
  } 

  .menu-title {
    margin: 15px;
  }
  .menu-items {
    margin-left: calc(50% - 390px);
  }
}

@media (max-width: 749px) {
  .menu-items {
    z-index: 100;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
  }

  .menu-items-show {
    display: inline;
  }

  .menu-title {
    display: inline-block;
    width: calc(100% - 130px);
  }

  .menu-transition {
    -webkit-transition: all 300ms cubic-bezier(0, 0.085, 0.68, 0.53);
    -moz-transition: all 300ms cubic-bezier(0, 0.085, 0.68, 0.53);
    -ms-transition: all 300ms cubic-bezier(0, 0.085, 0.68, 0.53);
    transition: all 300ms cubic-bezier(0, 0.085, 0.68, 0.53);
  }
}