* {
  font-family: Open Sans;
  font-weight: 500;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  margin: 0;
  padding: 0;
  border: 0;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
